import React from 'react'
import { withTheme } from 'emotion-theming'
import GlobalCss from './GlobalCss.styles'
import MegaFormStyles from './MegaForm.styles'
import ChatStyles from './Chat.styles'
import ZipFormBannerStyles from './ZipFormBanner.styles'
import ModalStyles from './Modal.styles'

const GlobalStyles = withTheme(() => (
  <>
    <GlobalCss />
    <MegaFormStyles />
    <ChatStyles />
    <ModalStyles />
    <ZipFormBannerStyles />
  </>
))

export default GlobalStyles
