import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const MegaFormStyles = withTheme(() => (
  <Global
    styles={css`
      .leshen-mega-form-wrapper {
        .leshen-column-wrapper {
          align-items: center;

          .leshen-typography-body {
            text-align: center;
          }
        }

        .leshen-phone-cta {
          margin-left: auto;
          margin-right: auto;
        }

        .submit-button {
          margin-left: auto;
          margin-right: auto;
        }
      }
    `}
  />
))

export default MegaFormStyles
