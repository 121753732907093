import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const ModalStyles = withTheme(() => (
  <Global
    styles={css`
      .leshen-modal {
        @media (max-width: 940px) {
          padding: 0 !important;
        }
      }

      .bsp-modal {
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;

        .content {
          margin-bottom: 0;
        }

        .gatsby-image-wrapper {
          margin-bottom: 0 !important;
        }

        .leshen-link-button,
        .leshen-phone-cta {
          @media (max-width: 940px) {
            width: 100%;
            display: block;
          }
        }
      }
    `}
  />
))

export default ModalStyles
